
$width: 800px;
$halfw: 400px;
$margin-fix: -16px;
$red: #ED1C25;
$gray: #232323;
$gray-border: 2px solid rgba(255, 255, 255, 0.5);
$shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);

@mixin small-text {
    font-weight: 400;
    font-size: 0.8rem;
    opacity: 0.5;
}
@mixin button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 0.8rem;
    width: 100%;
    background: white;
    color: black;
    border: 2px solid white;
    outline: none;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
}
@mixin credits {
    display: block;
    text-align: center;
    padding-bottom: 32px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    > div:nth-of-type(1) {
        font-size: 2rem;
        color: white !important;
    }
    > div:nth-of-type(2) {
        font-size: 1rem;
        opacity: 0.5;
    }
}
@keyframes scale-in {
    0% {
      transform: scale(0)
    }
    100% {
      transform: scale(1)
    }
}
@mixin scale-in-animation {
    transform: scale(0);
    transform-origin: center;
    animation: scale-in 0.2s cubic-bezier(0, 0, 0.58, 1);
    animation-fill-mode: forwards;
}
@keyframes slide-in-from-right {
    0% {
      transform: translateX(50vw)
    }
    100% {
      transform: translateX(0)
    }
}
@mixin slide-in-from-right-animation {
    transform: translateX(50vw);
    transform-origin: center;
    animation: slide-in-from-right 0.2s cubic-bezier(0, 0, 0.58, 1);
    animation-fill-mode: forwards;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@mixin fade-in-animation {
    opacity: 0;
    animation: fade-in 0.2s cubic-bezier(0, 0, 0.58, 1);
    animation-fill-mode: forwards;
}

// ::-webkit-scrollbar {
//     width: 0;
// }

::placeholder {
    color: white;
    opacity: 0.5;
}

* {
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    width: 100%;
    height: auto;
    overflow-y: auto;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
    background: black;
    color: white;
}

.route-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

header {
    cursor: pointer;
    box-shadow: $shadow;
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    > div {
        display: inline-block;
    }
    > .mobile-menu {
        width: 58px;
        text-align: right;
    }
    .badge {
        position: absolute;
        top: -4px;
        right: -9px;
        border-radius: 4px;
        background: $red;
        height: 16px;
        width: 16px;
        font-size: 8px;
        line-height: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .options {
        display: flex;
        justify-content: space-around;
        align-items: center;
        > div {
            margin: 0 16px;
            opacity: 0.5;
            > span {
                font-size: 0.9rem;
                display: inline-block;
                height: 32px;
            }
            &.active, &:hover {
                opacity: 1;
                > span {
                    border-bottom: 2px solid $red;
                }
            }
        }
    }

    .icon {
        display: inline-block;
        position: relative;
        &:first-child {
            margin-right: 16px;
        }
    }

    > div:nth-of-type(3) {
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .account-button {
            > button {
                display: inline-block;
                width: 150px;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: -8px;
            }

            position: relative;
            .account-menu {
                @include slide-in-from-right-animation;
                box-shadow: $shadow;
                padding: 32px;
                position: absolute;
                width: 400px;
                top: 36px;
                right: 0;
                background: black;
                background-color: $gray;

                &:active {
                    border: 2px solid white;
                }
                .credits {
                    @include credits;
                    border: none;
                }
                .connect {
                    width: 100%;
                }
            }
        }
    }
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background: black;
    transform: translateX(100vw);
    transition: transform 0.2s cubic-bezier(0, 0, 0.58, 1);
    &.active {
        box-shadow: $shadow;
        transform: translateX(0)
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    
    > div:first-of-type {
        margin-top: 96px;
    }
    
    > div {
        width: 100%;

        .credits {
            @include credits;
        }
        .options {
            margin: 16px 32px;
            > div {
                height: 64px;
                opacity: 0.5;
                display: flex;
                justify-content: space-around;
                align-items: center;
                &.active, &:focus-within {
                    opacity: 1;
                    > span {
                        display: inline-block;
                        border-bottom: 2px solid $red;
                        height: 32px;
                    }
                }
            }
        }
        .connect {
            padding: 0 16px 64px 16px;
            width: 100%;
        }
    }
}

section {
    margin-top: 64px;
}

section.items {
    text-align: center;
    margin-top: 96px;
    > h1 {
        margin: 32px 0;
        font-size: 1.4rem;
    }
    > p {
        font-size: 0.8rem;
        color: #888;
        margin: 16px;
    }
    > .ending {
        color: white;
        text-align: center;
        font-size: 1.2rem;
    }
    > .pieces {
        color: white;
        text-align: left;
    }

    > .bg {
        z-index: -1;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        opacity: 0.2;
        background: linear-gradient(to top, transparent, $red, transparent);
    }

    > main {
        margin-bottom: 32px;
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        > .item {
            cursor: pointer;
            position: relative;
            margin-right: 0.5vw;
            margin-bottom: 0.35vw;
            transition: 0.2s cubic-bezier(0, 0, 0.58, 1);
            > img {
                @include scale-in-animation;
                width: 100%;
                margin-bottom: -5px;
            }
            > .flame {
                position: absolute;
                bottom: calc(4px - 0.35vw);
                left: 4px;
                width: 12px;
                > img {
                    width: 10px;
                }
            }
            > .heart, > .heart-outline, > .heart-explode {
                @include fade-in-animation;
                position: absolute;
                top: 8px;
                right: calc(8px + 0.5vw);
                width: 16px;
                > img {
                    position: absolute;
                    width: 100%;
                }
            }
            > .heart-explode {
                z-index: 10000;
                top: calc(50% - 8px);
                left: calc(50% - 16px);
                > img {
                    transform: scale(0);
                    transform-origin: center;
                }
            }
            > .heart-outline {
                display: none;
            }
            &:hover {
                > .heart-outline {
                    display: block;
                }
                filter: brightness(2);
            }
        }
    }
}

section.token {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .media {
        position: relative;
        flex-grow: 1;
        .video-wrap {
            img, video {
                width: 100%;
            }
            img {
                @include scale-in-animation;
            }
            video {
                display: none;
            }
            .lds-loader {
                position: absolute;
                top: calc(50% - 32px);
                left: calc(50% - 32px);
                z-index: 1000;
            }
        }
        > .heart, > .heart-explode {
            z-index: 1;
            position: absolute;
            top: 8px;
            right: 8px;
            width: 24px;
            > img {
                width: 100%;
            }
        }
        > .heart-explode {
            z-index: 10000;
            top: calc(50% - 8px);
            left: calc(50% - 8px);
            > img {
                transform: scale(0);
                transform-origin: center;
            }
        }
    }
    .heading {
        text-align: center;
        > h2 {
            font-weight: 800;
            margin: 0;
        }
        > time {
            @include small-text;
        }
    }
    .content {
        width: 100%;
        margin: 32px;
    }
    .ending {
        margin-top: 16px;
        > h2 {
            font-weight: 800;
            font-size: 1rem;
            margin: 0;
        }
        > p {
            @include small-text;
        }
    }
    .bids-type {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
            @include small-text;
        }
        .amount {
            font-weight: 800;
            font-size: 1.4rem;
        }
        > div {
            width: 50%;
        }
        > div:nth-of-type(1) {
            border-right: 2px solid rgba(255, 255, 255, 0.25);
        }
        > div:nth-of-type(2) {
            text-align: right;
        }
        .ending, .ending p {
            margin: 0;
        }
    }
    .description {
        margin-top: 16px;
        > h4 {
            font-weight: 800;
            margin: 0;
        }
        > p {
            @include small-text;
        }
    }
    .select, .button {
        @include button;

        &.edition {
            background: black;
            color: white;
            border: $gray-border;

            > div:first-child {
                color: red;
            }
            img {
                filter: invert(100%);
            }
            &.active, &:hover {
                border: 2px solid white;
            }
        }
    }
    .bids {
        margin-top: 32px;
        h4 {
            margin: 0;
            padding-bottom: 16px;
            border-bottom: $gray-border;
        }
        > div div {

            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div:first-child {
                opacity: 0.5;
            }
        }
    }
}

section.bids {
    > main {
        margin-top: 128px;
        > .bid {
            .button {
                @include button;
            }
            margin: 16px;
            padding: 32px;
            background: $gray;
            text-align: center;

            .left {
                > p:first-of-type {
                    font-size: 1.2rem;
                }
            }


            > img {
                width: 50vw;
                max-width: 300px;
            }
        }
    }
}

section.credits {
    padding: 32px;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 64px);

    p {
        opacity: 0.5;
    }
    
    form {
        margin-top: 32px;
    }
    .card {
        margin-top: 16px;
        padding: 10px;
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        color: white;
        border: none;
        outline: none;
    }

}

section.page {
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    background: black;
    h1 {
        .red {
            color: $red;
            background: black !important;
        }
    }
    h2 {
        color: white;
        font-size: 1.1rem;
    }
    p, ul, ol {
        color:#888;
        font-weight: 400;
        span {
            color: white;
        }
    }
    .number {
        font-weight: 600;
        color: white;
        span {
            color: $red;
        }
    }
    hr {
        border: 1px solid #444;
    }
}

.credits-loading {
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background:rgb(0, 0, 0, 0.75);
    z-index: 3000;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


/// special sections e.g. modals or overlays

section.edition {
    margin-top: 0;
    header {
        margin-top: 0;
        z-index: 3001;
        padding: 0 32px;
    }
    > div {
        background: rgba(0, 0, 0, 0.5);
        z-index: 3000;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 32px;
        height: 100%;
        width: 100%;
        overflow: scroll;
        > div {
            box-shadow: $shadow;
            > div {
                padding: 32px 0;
                padding-bottom: 64px;
                background: black;
                > div {
                    padding: 16px 32px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &.available {
                        &:hover {
                            cursor: pointer;
                            background: rgb(255, 255, 255, 0.25);
                        }
                    }
                    &.header {
                        cursor: pointer;
                    }
                    > div:nth-of-type(1) {
                        flex: 0 0 3rem;
                        color: $red;
                    }
                    > div:nth-of-type(2) {
                        flex: 1;
                    }
                    > div:nth-of-type(3) {
                        text-align: right;
                    }
                }
            }
        }
    }
    .not-available {
        opacity: 0.5;
        div {
            color: white !important;
        }
    }
}

section.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    margin: 0;
    .background, .content {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
    }
    .content {
        background: none;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrap {
            box-shadow: $shadow;
            text-align: center;
            margin: 16px;
            background: $gray;
            padding: 32px;
            flex: 1;
            position: relative;
            .button {
                @include button;
                justify-content: space-around;
            }
            .close {
                position: absolute;
                top: 16px;
                right: 16px;
            }
        }

    }
}

footer {
    margin-bottom: 32px;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    > div {
        margin: 16px 0;
    }
    .social {
        img {
            margin: 0 16px;
        }
    }
    .tos {
        cursor: pointer;
        span {
            margin: 0 8px;
        }
    }
}

button, input, select {
    @include button;
    &.text {
        background: none;
        border: none;
        color: rgba(255, 255, 255, 0.5)
    }
}

button {
    &.dark {
        background: none;
        color: white;
        border: $gray-border;
        img {
            filter: invert(100%);
        }
        &.active, &:hover {
            border: 2px solid white;
        }
    }
    &:disabled {
        background:rgb(255, 255, 255, 0.5);
        border-color: transparent;
        cursor: not-allowed;
    }
}

input {
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent;
    color: white;
}

a, a:visited, a:focus {
    color: white;
}
a:hover {
    color: $red;
}

/// helper classes to override

.green {
    background: #00C08C !important;
    border-color: #00C08C !important;
}
.red-text {
    color: $red;
}
.red {
    background: #FF595D !important;
    border-color: #FF595D !important;
}
.deep-red {
    background: $red !important;
    border-color: $red !important;
    color: white !important;
}

.text-white {
    color: white !important;
}

.top-margin-16 {
    margin-top: 16px;
}

.width-auto {
    width: auto;
}

.center {
    text-align: center !important;
    justify-content: space-around !important;
}

/// pulse

.pulse {
    transform-origin: center;
    animation: pulse-frames 1s infinite;
}

@keyframes pulse-frames {
    0% {
      transform: scale(1)
    }
    100% {
      transform: scale(1.1)
    }
  }

/// loading animation

.lds-loader {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-loader div {
display: inline-block;
position: absolute;
left: 8px;
width: 16px;
background: #fff;
animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-loader div:nth-child(1) {
left: 8px;
animation-delay: -0.24s;
}
.lds-loader div:nth-child(2) {
left: 32px;
animation-delay: -0.12s;
}
.lds-loader div:nth-child(3) {
left: 56px;
animation-delay: 0;
}
@keyframes lds-loader {
0% {
    top: 8px;
    height: 64px;
}
50%,
100% {
    top: 24px;
    height: 32px;
}
}