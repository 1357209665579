@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide-in-from-right {
  0% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
::placeholder {
  color: white;
  opacity: 0.5;
}

* {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  background: black;
  color: white;
}

.route-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

header {
  cursor: pointer;
  box-shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
header > div {
  display: inline-block;
}
header > .mobile-menu {
  width: 58px;
  text-align: right;
}
header .badge {
  position: absolute;
  top: -4px;
  right: -9px;
  border-radius: 4px;
  background: #ED1C25;
  height: 16px;
  width: 16px;
  font-size: 8px;
  line-height: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header .options {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header .options > div {
  margin: 0 16px;
  opacity: 0.5;
}
header .options > div > span {
  font-size: 0.9rem;
  display: inline-block;
  height: 32px;
}
header .options > div.active, header .options > div:hover {
  opacity: 1;
}
header .options > div.active > span, header .options > div:hover > span {
  border-bottom: 2px solid #ED1C25;
}
header .icon {
  display: inline-block;
  position: relative;
}
header .icon:first-child {
  margin-right: 16px;
}
header > div:nth-of-type(3) {
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header > div:nth-of-type(3) .account-button {
  position: relative;
}
header > div:nth-of-type(3) .account-button > button {
  display: inline-block;
  width: 150px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -8px;
}
header > div:nth-of-type(3) .account-button .account-menu {
  transform: translateX(50vw);
  transform-origin: center;
  animation: slide-in-from-right 0.2s cubic-bezier(0, 0, 0.58, 1);
  animation-fill-mode: forwards;
  box-shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);
  padding: 32px;
  position: absolute;
  width: 400px;
  top: 36px;
  right: 0;
  background: black;
  background-color: #232323;
}
header > div:nth-of-type(3) .account-button .account-menu:active {
  border: 2px solid white;
}
header > div:nth-of-type(3) .account-button .account-menu .credits {
  display: block;
  text-align: center;
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  border: none;
}
header > div:nth-of-type(3) .account-button .account-menu .credits > div:nth-of-type(1) {
  font-size: 2rem;
  color: white !important;
}
header > div:nth-of-type(3) .account-button .account-menu .credits > div:nth-of-type(2) {
  font-size: 1rem;
  opacity: 0.5;
}
header > div:nth-of-type(3) .account-button .account-menu .connect {
  width: 100%;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background: black;
  transform: translateX(100vw);
  transition: transform 0.2s cubic-bezier(0, 0, 0.58, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
nav.active {
  box-shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);
  transform: translateX(0);
}
nav > div:first-of-type {
  margin-top: 96px;
}
nav > div {
  width: 100%;
}
nav > div .credits {
  display: block;
  text-align: center;
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.25);
}
nav > div .credits > div:nth-of-type(1) {
  font-size: 2rem;
  color: white !important;
}
nav > div .credits > div:nth-of-type(2) {
  font-size: 1rem;
  opacity: 0.5;
}
nav > div .options {
  margin: 16px 32px;
}
nav > div .options > div {
  height: 64px;
  opacity: 0.5;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
nav > div .options > div.active, nav > div .options > div:focus-within {
  opacity: 1;
}
nav > div .options > div.active > span, nav > div .options > div:focus-within > span {
  display: inline-block;
  border-bottom: 2px solid #ED1C25;
  height: 32px;
}
nav > div .connect {
  padding: 0 16px 64px 16px;
  width: 100%;
}

section {
  margin-top: 64px;
}

section.items {
  text-align: center;
  margin-top: 96px;
}
section.items > h1 {
  margin: 32px 0;
  font-size: 1.4rem;
}
section.items > p {
  font-size: 0.8rem;
  color: #888;
  margin: 16px;
}
section.items > .ending {
  color: white;
  text-align: center;
  font-size: 1.2rem;
}
section.items > .pieces {
  color: white;
  text-align: left;
}
section.items > .bg {
  z-index: -1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  opacity: 0.2;
  background: linear-gradient(to top, transparent, #ED1C25, transparent);
}
section.items > main {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
section.items > main > .item {
  cursor: pointer;
  position: relative;
  margin-right: 0.5vw;
  margin-bottom: 0.35vw;
  transition: 0.2s cubic-bezier(0, 0, 0.58, 1);
}
section.items > main > .item > img {
  transform: scale(0);
  transform-origin: center;
  animation: scale-in 0.2s cubic-bezier(0, 0, 0.58, 1);
  animation-fill-mode: forwards;
  width: 100%;
  margin-bottom: -5px;
}
section.items > main > .item > .flame {
  position: absolute;
  bottom: calc(4px - 0.35vw);
  left: 4px;
  width: 12px;
}
section.items > main > .item > .flame > img {
  width: 10px;
}
section.items > main > .item > .heart, section.items > main > .item > .heart-outline, section.items > main > .item > .heart-explode {
  opacity: 0;
  animation: fade-in 0.2s cubic-bezier(0, 0, 0.58, 1);
  animation-fill-mode: forwards;
  position: absolute;
  top: 8px;
  right: calc(8px + 0.5vw);
  width: 16px;
}
section.items > main > .item > .heart > img, section.items > main > .item > .heart-outline > img, section.items > main > .item > .heart-explode > img {
  position: absolute;
  width: 100%;
}
section.items > main > .item > .heart-explode {
  z-index: 10000;
  top: calc(50% - 8px);
  left: calc(50% - 16px);
}
section.items > main > .item > .heart-explode > img {
  transform: scale(0);
  transform-origin: center;
}
section.items > main > .item > .heart-outline {
  display: none;
}
section.items > main > .item:hover {
  filter: brightness(2);
}
section.items > main > .item:hover > .heart-outline {
  display: block;
}

section.token {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
section.token .media {
  position: relative;
  flex-grow: 1;
}
section.token .media .video-wrap img, section.token .media .video-wrap video {
  width: 100%;
}
section.token .media .video-wrap img {
  transform: scale(0);
  transform-origin: center;
  animation: scale-in 0.2s cubic-bezier(0, 0, 0.58, 1);
  animation-fill-mode: forwards;
}
section.token .media .video-wrap video {
  display: none;
}
section.token .media .video-wrap .lds-loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  z-index: 1000;
}
section.token .media > .heart, section.token .media > .heart-explode {
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
}
section.token .media > .heart > img, section.token .media > .heart-explode > img {
  width: 100%;
}
section.token .media > .heart-explode {
  z-index: 10000;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}
section.token .media > .heart-explode > img {
  transform: scale(0);
  transform-origin: center;
}
section.token .heading {
  text-align: center;
}
section.token .heading > h2 {
  font-weight: 800;
  margin: 0;
}
section.token .heading > time {
  font-weight: 400;
  font-size: 0.8rem;
  opacity: 0.5;
}
section.token .content {
  width: 100%;
  margin: 32px;
}
section.token .ending {
  margin-top: 16px;
}
section.token .ending > h2 {
  font-weight: 800;
  font-size: 1rem;
  margin: 0;
}
section.token .ending > p {
  font-weight: 400;
  font-size: 0.8rem;
  opacity: 0.5;
}
section.token .bids-type {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.token .bids-type .label {
  font-weight: 400;
  font-size: 0.8rem;
  opacity: 0.5;
}
section.token .bids-type .amount {
  font-weight: 800;
  font-size: 1.4rem;
}
section.token .bids-type > div {
  width: 50%;
}
section.token .bids-type > div:nth-of-type(1) {
  border-right: 2px solid rgba(255, 255, 255, 0.25);
}
section.token .bids-type > div:nth-of-type(2) {
  text-align: right;
}
section.token .bids-type .ending, section.token .bids-type .ending p {
  margin: 0;
}
section.token .description {
  margin-top: 16px;
}
section.token .description > h4 {
  font-weight: 800;
  margin: 0;
}
section.token .description > p {
  font-weight: 400;
  font-size: 0.8rem;
  opacity: 0.5;
}
section.token .select, section.token .button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 0.8rem;
  width: 100%;
  background: white;
  color: black;
  border: 2px solid white;
  outline: none;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
}
section.token .select.edition, section.token .button.edition {
  background: black;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
section.token .select.edition > div:first-child, section.token .button.edition > div:first-child {
  color: red;
}
section.token .select.edition img, section.token .button.edition img {
  filter: invert(100%);
}
section.token .select.edition.active, section.token .select.edition:hover, section.token .button.edition.active, section.token .button.edition:hover {
  border: 2px solid white;
}
section.token .bids {
  margin-top: 32px;
}
section.token .bids h4 {
  margin: 0;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}
section.token .bids > div div {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.token .bids > div div > div:first-child {
  opacity: 0.5;
}

section.bids > main {
  margin-top: 128px;
}
section.bids > main > .bid {
  margin: 16px;
  padding: 32px;
  background: #232323;
  text-align: center;
}
section.bids > main > .bid .button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 0.8rem;
  width: 100%;
  background: white;
  color: black;
  border: 2px solid white;
  outline: none;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
}
section.bids > main > .bid .left > p:first-of-type {
  font-size: 1.2rem;
}
section.bids > main > .bid > img {
  width: 50vw;
  max-width: 300px;
}

section.credits {
  padding: 32px;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
}
section.credits p {
  opacity: 0.5;
}
section.credits form {
  margin-top: 32px;
}
section.credits .card {
  margin-top: 16px;
  padding: 10px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  outline: none;
}

section.page {
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  background: black;
}
section.page h1 .red {
  color: #ED1C25;
  background: black !important;
}
section.page h2 {
  color: white;
  font-size: 1.1rem;
}
section.page p, section.page ul, section.page ol {
  color: #888;
  font-weight: 400;
}
section.page p span, section.page ul span, section.page ol span {
  color: white;
}
section.page .number {
  font-weight: 600;
  color: white;
}
section.page .number span {
  color: #ED1C25;
}
section.page hr {
  border: 1px solid #444;
}

.credits-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 3000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

section.edition {
  margin-top: 0;
}
section.edition header {
  margin-top: 0;
  z-index: 3001;
  padding: 0 32px;
}
section.edition > div {
  background: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 32px;
  height: 100%;
  width: 100%;
  overflow: scroll;
}
section.edition > div > div {
  box-shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);
}
section.edition > div > div > div {
  padding: 32px 0;
  padding-bottom: 64px;
  background: black;
}
section.edition > div > div > div > div {
  padding: 16px 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.edition > div > div > div > div.available:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.25);
}
section.edition > div > div > div > div.header {
  cursor: pointer;
}
section.edition > div > div > div > div > div:nth-of-type(1) {
  flex: 0 0 3rem;
  color: #ED1C25;
}
section.edition > div > div > div > div > div:nth-of-type(2) {
  flex: 1;
}
section.edition > div > div > div > div > div:nth-of-type(3) {
  text-align: right;
}
section.edition .not-available {
  opacity: 0.5;
}
section.edition .not-available div {
  color: white !important;
}

section.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  margin: 0;
}
section.modal .background, section.modal .content {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
section.modal .content {
  background: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.modal .content .wrap {
  box-shadow: 0 0 8px black, 0 0 32px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 16px;
  background: #232323;
  padding: 32px;
  flex: 1;
  position: relative;
}
section.modal .content .wrap .button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 0.8rem;
  width: 100%;
  background: white;
  color: black;
  border: 2px solid white;
  outline: none;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  justify-content: space-around;
}
section.modal .content .wrap .close {
  position: absolute;
  top: 16px;
  right: 16px;
}

footer {
  margin-bottom: 32px;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
}
footer > div {
  margin: 16px 0;
}
footer .social img {
  margin: 0 16px;
}
footer .tos {
  cursor: pointer;
}
footer .tos span {
  margin: 0 8px;
}

button, input, select {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 0.8rem;
  width: 100%;
  background: white;
  color: black;
  border: 2px solid white;
  outline: none;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
}
button.text, input.text, select.text {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
}

button.dark {
  background: none;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
button.dark img {
  filter: invert(100%);
}
button.dark.active, button.dark:hover {
  border: 2px solid white;
}
button:disabled {
  background: rgba(255, 255, 255, 0.5);
  border-color: transparent;
  cursor: not-allowed;
}

input {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  color: white;
}

a, a:visited, a:focus {
  color: white;
}

a:hover {
  color: #ED1C25;
}

.green {
  background: #00C08C !important;
  border-color: #00C08C !important;
}

.red-text {
  color: #ED1C25;
}

.red {
  background: #FF595D !important;
  border-color: #FF595D !important;
}

.deep-red {
  background: #ED1C25 !important;
  border-color: #ED1C25 !important;
  color: white !important;
}

.text-white {
  color: white !important;
}

.top-margin-16 {
  margin-top: 16px;
}

.width-auto {
  width: auto;
}

.center {
  text-align: center !important;
  justify-content: space-around !important;
}

.pulse {
  transform-origin: center;
  animation: pulse-frames 1s infinite;
}

@keyframes pulse-frames {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.lds-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
@media (min-width: 992px) {
  nav, nav header {
    width: 55vw;
  }
  nav.active, nav header.active {
    transform: translateX(45vw);
  }

  header {
    height: 96px;
  }
  header > div {
    height: 32px;
  }
  header > div:nth-of-type(1) {
    width: 220px;
  }

  section {
    margin-top: 96px;
  }
  section:not(.items) {
    margin-left: auto;
    margin-right: auto;
  }

  section.credits {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }

  section.token {
    padding: 64px;
    align-items: flex-start;
  }
  section.token .media, section.token .content {
    flex: 0.5;
    margin-top: 0;
    margin-bottom: 64px;
  }
  section.token .content {
    padding-left: 64px;
    padding-right: 64px;
  }
  section.token .heading {
    text-align: left;
  }

  section.items {
    margin-top: 128px;
  }
  section.items > main {
    grid-template-columns: 25% 25% 25% 25%;
  }
  section.items > main > .item > .flame {
    bottom: calc(8px - 0.35vw);
    left: 8px;
    width: 16px;
  }
  section.items > main > .item > .flame > img {
    width: 16px;
  }
  section.items h1 {
    font-size: 2rem;
  }
  section.items > .bg {
    opacity: 0.2;
  }

  section.edition > div {
    top: 64px;
    padding: 0 25vw;
    overflow: hidden;
  }
  section.edition > div > div {
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  section.edition > div > div > div {
    background: #232323;
    padding-bottom: 0;
  }
  section.edition header {
    padding: black;
    padding: 32px calc(25vw + 32px);
  }

  section.modal .content {
    left: 25vw;
    width: 50vw;
  }

  section.page {
    left: 25vw;
    width: 50vw;
  }

  section.bids {
    width: 75vw;
  }
  section.bids > main > .bid {
    margin: 32px;
    padding: 32px;
    background: #232323;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  section.bids > main > .bid > div {
    margin: 0 16px;
  }
  section.bids > main > .bid > div.button {
    flex: 0 0 200px;
  }
  section.bids > main > .bid > div.left {
    text-align: left;
    flex: 1;
  }
  section.bids > main > .bid > div.left > p:first-of-type {
    font-size: 1.2rem;
  }
  section.bids > main > .bid > img {
    width: 25vw;
    max-width: 200px;
  }

  footer {
    margin: 16px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 64px);
  }
  footer .built-on {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  footer .built-on * {
    margin: auto 8px;
  }
}
@media (min-width: 1200px) {
  section:not(.items) {
    max-width: 1400px;
  }

  section.credits {
    max-width: 33.33vw;
  }

  section.edition > div {
    margin-top: 64px;
    padding: 0 33.33vw;
  }
  section.edition header {
    padding: 32px calc(33.33vw + 32px);
  }

  section.modal .content {
    left: 33.33vw;
    width: 33.33vw;
  }

  section.page {
    left: 33.33vw;
    width: 33.33vw;
  }
}
@media (min-width: 1600px) {
  section.bids {
    width: 50vw;
  }

  section.items > main {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
/*# sourceMappingURL=index.470d64a6.css.map */
